import { Button, Grid, Icon, createMuiTheme, withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import purple from '@material-ui/core/colors/purple';
import React, { Fragment } from 'react';

import { Footer } from '../components/Footer';
import Header from '../components/Header';
import { Nav } from '../components/Nav';
import { TopLayout } from '../components/TopLayout';
import businessHeader from '../static/images/businessHeader.jpg';
import { mediaLink } from '../util/redirects';

const pressPurple = '#796181';

const soundcloudSrc = 'https://w.soundcloud.com/player/?url=https%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F186229972%3Fsecret_token%3Ds-JDPze&visual=true&color=796181&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false';

const SoundCloudPlayer = () => (
  <iframe
    frameBorder="no"
    height="166"
    scrolling="no"
    src={soundcloudSrc}
    width="100%"
  />
);

const styles = (theme: any) => ({
  button: {
    '&:hover': {
      backgroundColor: 'lightgrey',
      color: 'black',
    },
    backgroundColor: pressPurple,
    color: 'white',
    marginBottom: '3em',
    marginTop: '2em',
  },
  icon: {
    color: pressPurple,
    fontSize: '0.75em',
  },
  stream: {
    marginTop: '1em',
  },
});

interface Props {
  classes: any;
}

const CheckIcon = ({ classes }: { classes: any }) => (
  <Icon className={`fa fa-check ${classes.icon}`} />
);

const App = (props: Props) => {
  const { classes } = props;

  return (
    <Fragment>
      <Nav />
      <Header src={businessHeader} />
      <Container maxWidth="md">
        <Grid
          alignItems="center"
          container
          direction="column"
          justify="center"
        >
          <Button
            className={classes.button}
            color="primary"
            href={mediaLink('/media/SatyrasisPressPack.zip', 'SatyrasisPressPack')}
            size="large"
            variant="contained"
          >
            Download Press Pack
          </Button>

          <Typography variant="h4">
            The Satyrasis Press Pack contains:
          </Typography> 

          <Typography>
            <CheckIcon classes={classes} /> Audio files for Sophomore album: “…Of The Dead”
          </Typography>
          <Typography>
            <CheckIcon classes={classes} /> Album Artwork in high resolution
          </Typography>
          <Typography>
            <CheckIcon classes={classes} /> Promotional Photos
          </Typography>
          <Typography>
            <CheckIcon classes={classes} /> Bio and Fact Sheet
          </Typography>
          <Typography>
            <CheckIcon classes={classes} /> Contact Information
          </Typography>


          <Typography className={classes.stream} variant="h6">
            Stream the entire album to get a quick idea:
          </Typography>
          <SoundCloudPlayer />
        </Grid>
      </Container>

      <Footer />
    </Fragment>
  );
};

const Intermediary = withStyles(styles)(App);

export default function Default() {
  return (
    <TopLayout>
      <Intermediary />
    </TopLayout>
  );
}
